import './scss/main.scss'

import $ from 'jquery';

import '@fortawesome/fontawesome-free/js/all.js'

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import 'owl.carousel';

import "bootstrap";

$(document).on('click', 'a.row-scroll', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 1000);
});

$('form[name="contato"]').submit(function(){
    $.ajax({
        type: "POST",
        url: 'contato.php',
        data: $(this).serialize(),
        async: true, 
        success: function(data) {
            if(data==1){
                $(".retorno").html("Sua mensagem foi enviada com sucesso, em breve entraremos em contato.");
            }else{
                $(".retorno").html("Ocorreu um erro ao tentar enviar sua mensagem. Por favor tente novamente mais tarde!");
            }
        }
    }); 

    return false;
});